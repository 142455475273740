import React from 'react';
import {
	Box,
	Button,
    Divider,
    Grid,
	LinearProgress,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Formik, Form, Field, useFormikContext } from 'formik';
import {
	TextField
  } from 'formik-mui';
import Layout from '../layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import {
	doc,
	getDoc,
} from "firebase/firestore";
import { db } from '../../../firebase-info';
import useLocalStorageState from 'use-local-storage-state'
import { dictatorreverse } from "../../utils/gameDescriptions";
import { navigate } from "gatsby";
import BeDictatorReverseUserForm from '../game-user-forms/be-dictator-reverse-user-form';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { GamesContext, AuthContext } from '../../contexts/contexts.js';

const formContainer = {
	width: '100%',
	marginTop: '32px',
};
const inputWidth = {
	minWidth: '80%'
};

const VisualizationOfUser = ({ initialValues }) => {
    const { i18n } = useTranslation();
    const [questionValues, setQuestionValues] = React.useState(initialValues);
    const formik = useFormikContext();

    const language = i18n.language;

    const markdownUser = dictatorreverse(`${language}User`, questionValues);

    React.useEffect(() => {
        setQuestionValues({...formik.values});
    }, [formik.values])

    return (
        <React.Fragment>
            <ReactMarkdown children={markdownUser || ''} remarkPlugins={[remarkGfm]} />
            <BeDictatorReverseUserForm {...questionValues} noButton={true} handleSubmit={() => {}} />
        </React.Fragment>
    );
}

const DictatorReverseGameForm = ({handleOnChange }) => {
	const { t } = useTranslation();
    const gamesInfo = React.useContext(GamesContext);
    const userInfo = React.useContext(AuthContext);
    const [experiment, setExperiment] = useLocalStorageState("experiment");
    const [gamePosition, setGamePosition] = useLocalStorageState("gamePosition", {defaultValue: undefined});

	const handleSubmit = async (values) => {
		try {
            if (gamePosition !== undefined) {
                const game = {...experiment.games[gamePosition], params: {...values}};
                
                experiment.games[gamePosition] = game;
            } else {
                const gameData = gamesInfo.filter((game) => game.id === "dictatorreverse");
                const game = {...gameData[0], params: {...values}};

                const gamesLikeThis = experiment.games.filter((g) => (game.id === g.id))
                const maxInstance = Math.max(gamesLikeThis.map((g) => g.instance))
                if(!maxInstance){
                    game.instance = 1;
                }
                else{
                    game.instance = maxInstance+1;
                }
                game["listId"] = `${game.id}_${game.instance}`;

                experiment.games.push(game);
            }
            
            setGamePosition(undefined);
            setExperiment(experiment);

		} catch(e) {
            console.log(e);
            //we should handle the error
		}
	}

	const validate = values => {
		const errors = {};
	 
		if (!values.currency) {
			errors.currency = t('Required');
        }

		if (isNaN(values.value)) {
			errors.value = t('Must be a number');
		}
       if (isNaN(values.increment)) {
			errors.increment = t('Must be a number');
		} else if (values.increment > 1 || values.increment < 0) {
            errors.increment = t('Increment must be a number between 0 and 1');
        } else if (values.increment === 0) {
            errors.increment = t("Increment can't be 0");
        }

		return errors;
	  };

    const goBack = () => {
        if(gamePosition !== undefined){
            navigate("/checkoutExperiment/");
        }
        else{
            navigate(-1);
        }
    }

	return(
        <Layout>
            <Formik
                initialValues={
                    (gamePosition !== undefined && experiment?.games[gamePosition].params) || {
                    currency: '$',
                    value: 100,
                    increment: 1
                    
                }}
                style={formContainer}
                onSubmit={async (values, actions) => { 
                    await handleSubmit(values)
                    actions.setSubmitting = false;
                    goBack();
                }}
                validate={(values) => { return validate(values) }}
            >
                {({submitForm, resetForm, isSubmitting}) => (
                    <Form onChange={handleOnChange}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Paper sx={{ height: '100%', paddingLeft: '16px', paddingRight: '16px' }}>
                                    <Typography fontFamily={'Noto Sans'} variant={'h4'} pt={1} pb={1}>
                                        <Trans>Customization for researcher</Trans>
                                    </Typography>
                                    <Divider/>
                                    <Box margin={1}>
                                        <Field
                                            component={TextField}
                                            style={inputWidth}
                                            name="currency"
                                            type="text"
                                            label={t("Currency")}
                                        />
                                    </Box>
                                    <Box margin={1}>
                                        <Field
                                            component={TextField}
                                            style={inputWidth}
                                            name="value"
                                            type="number"
                                            label={t("Add money")}
                                        />
                                    </Box>
                                    <Box margin={1}>
                                        <Field
                                            component={TextField}
                                            style={inputWidth}
                                            name="increment"
                                            type="number"
                                            step={0.01}
                                            label={t("select increment step")}
                                        />
                                    </Box>
                                    {isSubmitting && <LinearProgress />}
                                    <Box margin={1}>
                                        <Button
                                            sx={{margin: 1}}
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            disabled={isSubmitting || !userInfo?.userData.gamePermissions.dictatorreverse.usable}
                                            onClick={submitForm}
                                        >
                                            {gamePosition !== undefined ? 
                                                <Trans>Modify game parameters</Trans>
                                            :
                                                <Trans>Add game to experiment</Trans>
                                            }
                                        </Button>
                                        <Button
                                            sx={{margin: 1}}
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                resetForm();
                                            }}
                                        >
                                            <Trans>Reset values</Trans>
                                        </Button>
                                        <Button
                                            sx={{margin: 1}}
                                            variant="contained"
                                            color="error"
                                            size="large"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                goBack();
                                            }}
                                        >
                                            <Trans>Cancel</Trans>
                                        </Button>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Paper sx={{ height: '100%', paddingLeft: '16px', paddingRight: '16px' }}>
                                    <Typography fontFamily={'Noto Sans'} variant={'h4'} pt={1} pb={1}>
                                        <Trans>Participant representation</Trans>
                                        <Tooltip title={t("This is a visual representation of what the user will see, given your customization made on the other form. You can interact with the participant representation form but nothing will be saved.")}>
                                            <InfoIcon color="primary" pl={1} sx={{ verticalAlign: 'middle' }}/>
                                        </Tooltip>
                                    </Typography>
                                    <Divider/>
                                    <VisualizationOfUser initialValues={(gamePosition !== undefined && experiment?.games[gamePosition].params)} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Layout>
	);
}

export default DictatorReverseGameForm;